export const DEMO_YDY = "/demoydy"; // yangdanyan组件例子
export const DEMO_QJJ = "/demoqjj"; // qijiajun组件例子
export const LOAD_FAIL = "/fail"; // 加载失败
export const LOAD_NOPMS = "/nopms"; // 无权限访问
export const LOGIN = "/"; // 登录
export const AUTHORITY = "/authority"; // 账户权限
export const MARKET_MANAGE = "/marketManage"; // 市场管理
export const MARKET_DETAIL = "/marketDetail"; // 市场详情
export const MARKET_DETAIL_COPY = "/productCopy"; // 商品同步

export const OPERATION_LOG = "/operationLog"; // 操作日志
export const CUSTOMER = "/customer"; // 客户
export const CUSTOMERDETAIL = "/customerDetail"; // 客户详情
export const CATEGORY = "/category"; // 商品品类
export const COST_TYPE = "/costType"; // 费用类型
export const DIYMENU = "/diyMenu"; // 自定义菜单
export const PRODUCT = "/product"; //
export const PRODU_CATEGORY = "/produ_category"; //
export const VERSION = "/version"; // 版本管理
export const NEW_VERSION = "/new_version"; // 新建版本管理
export const EDIT_VERSION = "/edit_version"; // 编辑版本管理
export const VIEW_VERSION = "/view_version"; // 查看版本管理
export const OEM_VERSION = "/oem_version"; // oem版本管理
export const PROPERTY = "/property"; //招商平台
export const DEVICE = "/device"; // 设备管理
export const VIEW_DEVICE = "/view_device"; // 查看设备管理

export const CHANNEL = "/channel"; // 渠道商
export const ANNOUNCEMENT = "/announcement"; // 公告管理
export const ANNOUNCEMENT_CREATE = "/announcement/create"; // 公告管理新建
export const ANNOUNCEMENT_EDIT = "/announcement/edit"; // 公告管理编辑
export const ANNOUNCEMENT_DETAIL = "/announcement/detail"; // 公告管理详情
export const EquipmentUpgrade = "/updated"; // 设备升级
export const EquipmentUpgradeAdd = "/new_updated"; // 设备升级新增
export const SCREEN_MANAGE = "/screen"; //数据屏管理
export const PAYREG = "/pay_reg"; // 支付账户管理
export const PAYREG_Add = "/pay_reg_add"; // 支付账户新增、编辑
export const PAYREG_Edit = "/pay_reg_add"; // 支付账户编辑
export const PAYREG_Detail = "/pay_reg_detail"; // 支付账户详情
export const MARKET_GROUP = "/marketGroup"; // 市场群组
export const COMPANY_SCREEN = "/company_screen"; // 企业大屏管理
export const SCREEN_AD = "/screenAd"; // 信发管理
export const SUPERVISE = "/supervise"; // 监管平台管理
export const UPLOAD = "/upload"; // 文件上传
