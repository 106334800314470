<template>
	<button class="load-btn" @click="callback" :class="{disabled: disabled}" :disabled="disabled">
		<svg v-show="disabled" class="icon" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
			<path :fill="color" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
				<animateTransform attributeType="xml"
					attributeName="transform"
					type="rotate"
					from="0 25 25"
					to="360 25 25"
					dur="0.6s"
					repeatCount="indefinite"/>
			</path>
		</svg>
		<span>{{btnName}}</span>
	</button>
</template>

<script>
/**
 * @module loadBtn
 * @desc 速商 防止二次提交的按钮
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-12-13
 * @copyright 2021
 */
export default {
	name: 'loadbutton',
	props: {
		name: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '#fff',
		},
		callback: {},
		disabled: {
			type: Boolean,
			default: false,
		},
		btnName: {
			type: String,
			default: '确定',
		},
	},
	methods: {
		/**
		 * 点击时向父组件传值
		 * @author songkexin <songkexin@rongyi.com>
		 * @date 2021-01-09
		 */
		// clickBack(){
		// 	// this.callback();
		// 	this.$emit('sdnm-export', this.name);
		// },
	},
};
</script>

<style lang="less">
	.load-btn{
		padding-left: 17px;
		padding-right: 17px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		vertical-align: middle;
		.icon{
			width: 20px;
			height: 20px;
			margin-right: 4px;
		}
		&.disabled{
			// padding-left: 5px;
			// padding-right: 5px;
		}
	}
</style>