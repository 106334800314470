/**
 * @file utils.js
 * @desc 工具函数库
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
import Vue from 'vue';
import router from '@/router';
import store from '../vuex/index';
import * as CONST from '../modules/CONSTs';
import * as URL from '../modules/URLs';
export const apiPost = function(obj){
	let params = {
		url: '',
		data: {},
		success(data){},
		error(data){},
	};
	for(let key in obj){
		if(obj[key]){
			params[key] = obj[key];
		}
	}
	setTimeout(() => {
		Vue.http.post(params.url, params.data)
		.then(res => {
			if(res.data.code === CONST.CODE_NOLOGIN){
			// 没有登录
			// todo 清空用户所有的信息
				store.dispatch('clearUserInfo');
				router.push(URL.LOGIN);
			}
			// else if(res.data.code === CONST.CODE_NOMARKET){
			// 没有选中农贸市场
			// router.push(URL.SELMARKET);
			// }
			// else{
			params.success(res.data);
			// }
		}, res => {
			params.error(res.data);
		});
	}, 1);
};
/**
 * 表格的高度自适应
 * @author wurongjian <wurongjian@rongyi.com>
 * @date   2021-09-27
 * @param  {Number}   clHeight  盒子高度
 * @param  {Number}   rmHeight  盒子除表格的剩余高度
 * @param  {Number}   showTrNum 表格的行数
 */
export const tableResize = function(clHeight, rmHeight, showTrNum){
	if(showTrNum === 0){
		showTrNum = 1;
	}
	let ctHeight = clHeight - rmHeight;
	let allTrHeight = showTrNum * 41;
	let tbHeight = ctHeight;
	if(allTrHeight < ctHeight){
		tbHeight = allTrHeight;
	}
	return tbHeight;
};
/**
 * 数据重构
 * @author wurongjian <wurongjian@rongyi.com>
 * @date   2021-09-30
 * @param  {Array}   vueData 原数据
 */
export const parse = function(vueData){
	return JSON.parse(JSON.stringify(vueData));
};
/**
 * 个位数前加0
 * @author wurongjian <wurongjian@rongyi.com>
 * @date   2021-09-30
 * @param  {String | Number}   num 数据
 * @param  {Number}   bit 几位
 */
export const fillZero = function(num, bit = 0){
	let str = num.toString();
	if(str.length < bit){
		let n = bit - str.length;
		while(n--){
			str = '0' + str;
		}
	}
	return str;
};

/**
 * 获取格式化的时间
 * @author wurongjian <wurongjian@rongyi.com>
 * @date   2021-09-30
 * @param  {Date}     time 时间
 */
export const getDate = function(time = new Date().getTime()){
	let date = new Date(time);
	let formatDate = `${date.getFullYear()}-${fillZero(date.getMonth() + 1, 2)}-${fillZero(date.getDate(), 2)}`;
	return formatDate;
};
/**
 * 获取格式化的当月月初时间
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-10-30
 * @param  {Date}     time 时间
 */
export const getFirstDate = function(time = new Date().getTime()){
	let date = new Date(time);
	let formatDate = `${date.getFullYear()}-${fillZero(date.getMonth() + 1, 2)}-${fillZero(1, 2)}`;
	return formatDate;
};
/**
 * 获取格式化的上个月月初时间
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-10-30
 * @param  {Date}     time 时间
 */
export const getLastFirstDate = function(time = new Date().getTime()){
	let date = new Date(time);
	let formatDate = '';
	if(date.getMonth() === 0){
		formatDate = `${date.getFullYear() - 1}-${fillZero(12, 2)}-${fillZero(1, 2)}`;
	}
	else{
		formatDate = `${date.getFullYear()}-${fillZero(date.getMonth(), 2)}-${fillZero(1, 2)}`;
	}
	return formatDate;
};
/**
 * 获取格式化的昨天时间
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-11-27
 * @param  {Date}     time 时间
 */
export const getYesterdayDate = function(time = new Date().getTime() - 24 * 60 * 60 * 1000){
	let date = new Date(time);
	let formatDate = `${date.getFullYear()}-${fillZero(date.getMonth() + 1, 2)}-${fillZero(date.getDate(), 2)}`;
	return formatDate;
};
/**
 * 只获取到年月的时间(上个月)
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-10-30
 * @param  {Date}     time 时间
 */
export const getMonthDate = function(time = new Date().getTime()){
	let date = new Date(time);
	let formatDate = '';
	if(date.getMonth() === 0){
		formatDate = `${date.getFullYear() - 1}-${fillZero(12, 2)}`;
	}
	else{
		formatDate = `${date.getFullYear()}-${fillZero(date.getMonth(), 2)}`;
	}
	return formatDate;
};

/**
 * 浏览器本地暂存加载失败时显示的数据
 * @author wurongjian <wurongjian@rongyi.com>
 * @date   2021-09-30
 * @param  {Object}   pageInfo 页面的数据
 */
export const localSetItemFail = function(pageInfo){
	localStorage.setItem('loadFailTitles', JSON.stringify(pageInfo.titles));
	localStorage.setItem('loadFailBackBtn', pageInfo.backBtn);
	localStorage.setItem('loadFailBackPath', pageInfo.backPath);
	localStorage.setItem('loadFailToPath', pageInfo.toPath);
};

/**
 * 手机号格式、号段（运营商）校验
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-10-07
 * @param {string|int} telphone 待校验手机号
 * @return {returnObject}
 */
export const checkMobile = function(telphone){
	let isChinaMobile	=	/^134[0-8]\d{7}$|^(?:13[5-9]|14[78]|15[0-27-9]|178|18[2-478]|198)\d{8}$/; // update 198/148 @date 2021-08-08
	let isChinaUnion	=	/^(?:13[0-2]|14[56]|15[56]|166|176|18[56])\d{8}$/; // update 166/146 @date 2021-08-08
	// ^1349\d{7}$ 1349号段 电信方面没给出答复，视作不存在
	// 2021-04-24 追加 173号段 from 测试反馈
	let isChinaTelcom	=	/^(?:133|153|17[37]|18[019]|199)\d{8}$/; // update 199/1740/1741 @date 2021-08-08
	let isOtherTelphone	=	/^(?:170([059])\d{7}|(?:174[01])\d{8}|144\d{10}|141\d{10})$/; // 1740、1741电信和工信部的卫星通信号段 144 十三位移动物联网 / 141 十三位电信物联网
	// telphone = this.trim(telphone);
	if(telphone.length !== 11){
		return false;
	}
	else{
		if(isChinaMobile.test(telphone)){
			return true;
		}
		else if(isChinaUnion.test(telphone)){
			return true;
		}
		else if(isChinaTelcom.test(telphone)){
			return true;
		}
		else if(isOtherTelphone.test(telphone)){
			return true;
		}
		else{
			return false;
		}
	}
};
/**
 * 格式化数字/数字格式的字符串为带千分位和两位小数的字符串
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-11-22
 */
export const amountNumberFormat = function(number, bit = 2){
	let flag = '';
	if(number === null || number === undefined){
		number = 0.0;
	}
	if(typeof number === 'string'){
		number = parseFloat(number);
	}
	if(isNaN(number)){
		number = 0.0;
	}
	if(number < 0){
		number = Math.abs(number);
		flag = '-';
	}
	number = number.toFixed(bit);
	// log(number);
	number = number.split('.').map((el, index) => {
		if(index === 0){
			return addThousandth(el);
		}
		else{
			return el;
		}
	}).join('.');
	return `${flag}${number}`;
};
let addThousandth = function(str){
	let len = 0;
	if(str.length % 3 !== 0){
		len = 3 - str.length % 3;
		str = new Array(len + 1).join('0') + str;
	}
	let ret = [];
	let index = 0;
	while(index < str.length){
		ret.push(str.substr(index, 3));
		index += 3;
	}
	ret = ret.join(',').split('');
	while(len--){
		ret.shift();
	}
	return ret.join('');
};
/**
 * 树结构添加showAll参数
 * @author qijiajun <qijiajun@rongyi.com>
 * @date   2021-11-27
 * @param  {Object}   el
 */
export const reTreeShow = function(el){
	if(typeof (el.children) !== 'undefined'){
		el.showAll = true;
		el.children.map(cel => {
			return reTreeShow(cel);
		});
	}
	return el;
};
/**
 * 校验是否为空
 * @author wurongjian <wurongjian@rongyi.com>
 * @date   2021-12-05
 * @param  {String | Object}   data 校验的内容
 */
export const isEmpty = function(data){
	if(typeof (data) === 'undefined' || data === '' || data === null){
		return true;
	}
	else{
		return false;
	}
};
