<template>
  <div id="layer-content">
    <div class="layer" v-show="show" @click="close">
      <!-- <div class="l-mask" :style="{height: maskHeight + 'px'}" @click="close"></div> -->
      <div class="l-body centered" :style="{ width: width + 'px' }">
        <div class="l-head" @click.stop="">
          <label class="title">{{ title }}</label>
          <i class="sdnm-close" @click="close"></i>
        </div>
        <!-- <div class="l-content" v-if="showFoot && setFootHeight" :style="{height: height - 145 +'px'}">
					<slot></slot>
				</div>
				<div class="l-content" v-if="!showFoot && setFootHeight" :style="{height: height - 60 +'px'}">
					<slot></slot>
				</div> -->
        <div class="l-content" v-if="showFoot && setFootHeight" @click.stop="">
          <slot></slot>
        </div>
        <div class="l-content" v-if="!showFoot && setFootHeight" @click.stop="">
          <slot></slot>
        </div>
        <div class="l-content" v-if="!setFootHeight" @click.stop="">
          <slot></slot>
        </div>
        <div class="l-foot" v-if="showFoot" @click.stop="">
          <load-button
            class="btn primary"
            :class="{ thin: saveBtn.length < 4 }"
            name="保存"
            :disabled="btnLoadDisabled"
            :callback="save"
            >{{ saveBtn }}</load-button
          >
          <button class="btn secondary thin" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/layers
 * @desc 传蔬农贸版 弹出层
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-28
 * @copyright 2021
 */
export default {
  name: "layer",
  props: {
    show: {
      type: Boolean,
      default: !true,
    },
    showFoot: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 500,
    },
    saveBtn: {
      type: String,
      default: "保存",
    },
    setFootHeight: {
      type: Boolean,
      default: true,
    },
    closeCallBack: {},
    saveCallBack: {},
    btnLoadDisabled: {
      // 确定按钮添加loading特效
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    /**
     * 关闭弹出层
     * @author wurongjian <wurongjian@fresfirst.cn>
     * @date   2021-07-07
     */
    close() {
      this.closeCallBack();
    },
    /**
     * 确定保存弹出层
     * @author wurongjian <wurongjian@fresfirst.cn>
     * @date   2021-07-07
     */
    save() {
      if (this.btnLoadDisabled) {
        return;
      }
      this.saveCallBack();
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
#layer-content {
  .l-foot {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      .mgr(12px);
      .bgc(#257873);
      .fc(14px, #fff);
      .brd(1px, solid, #257873);
      &:last-child {
        .mgr(0);
      }
    }
  }
  .thin {
    .pdl(0);
    .pdr(0);
  }
}
</style>
