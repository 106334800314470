/**
 * @file vuex/mutation-types.js
 * @desc 传蔬-农贸版运营后台 vuex mutation-types
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
export const SET_ALERT = 'SET_ALERT';
export const SET_SIDE_MENU = 'SET_SIDE_MENU';
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const SET_APPTITLE = 'SET_APPTITLE';
