/**
 * @file vuex/actions.js
 * @desc 传蔬-农贸版运营后台 vuex actions
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
import * as types from './mutation-types';
/**
 * 设置错误信息
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-09
 * @param {function} context.commit
 * @param  {String}   msg 错误信息
 */
export const setAlert = ({ commit }, { msg, type }) => {
	commit(types.SET_ALERT, { msg, type });
};
/**
 * 设置用户信息
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @param {function} context.commit
 * @return {object} data 用户信息
 */
export const setUserInfo = ({ commit }, data) => {
	commit(types.SET_USER_INFO, data);
};
/**
 * 清空用户信息
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @param {function} context.commit
 */
export const clearUserInfo = ({ commit }) => {
	commit(types.CLEAR_USER_INFO);
};
/**
 * 获取侧边目录菜单
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-09
 * @param {function} context.commit
 * @param {object} data 菜单数据
 * @param {string} path 地址
 */
export const setSideMenu = ({ commit }, data) => {
	commit(types.SET_SIDE_MENU, data);
};
/**
 * 设置页面的标题
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-09
 * @param {function} context.commit
 * @param  {Array}   data           页面的标题数据
 */
export const setApptitle = ({ commit }, data) => {
	commit(types.SET_APPTITLE, data);
};
