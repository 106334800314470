<template>
  <div id="sidebar">
    <div class="logo">
      <i class="sdnm-sudian"></i>
      <i class="sdnm-su"></i>
      <i class="sdnm-dian"></i>
      <img src="../assets/sidebar/logo.png" style="width:67px;height:23px" />
      <div class="foot">运营后台</div>
    </div>
    <div class="bar-info">
      <ul>
        <li v-for="(menu, index) in menus" :key="index">
          <div
            class="p-menu"
            @click="toMenu(menu, index)"
            :class="{
              active: menu.url === path,
              'has-son': menu.children && menu.children.length,
              expanded: menu.expanded,
            }"
          >
            <i :class="menu.class"></i>
            <span>{{ menu.name }}</span>
          </div>
          <div
            class="p-menu is-son"
            @click="toMenu(sonMenu, ind)"
            :class="{ active: sonMenu.url === path }"
            v-show="menu.expanded"
            v-if="menu.children && menu.children.length"
          >
            <div v-for="(sonMenu, ind) in menu.children" :key="ind">
              <span>{{ sonMenu.name }}</span>
              <div v-if="menu.children && menu.children.length">
                <div
                  class="p-menu is-son"
                  @click="toMenu(sonMenu, ind)"
                  :class="{ active: sonMenu.url === path }"
                  v-show="menu.expanded"
                  v-for="(sonMenu, ind) in menu.children"
                  :key="ind"
                >
                  <span>{{ sonMenu.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * @module components/sidebar
 * @desc 传蔬农贸版后台 侧边菜单
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */

import * as URL from "../modules/URLs";
import router from "@/router";
import store from ".././vuex";
import { mapGetters } from "vuex";
export default {
  name: "sidebar",
  computed: {
    ...mapGetters(["sideMenu"]),
  },
  data() {
    return {
      path: "",
      menus: [],
    };
  },
  created() {
    this.menus = Array.from(this.sideMenu);
  },
  watch: {
    $route(newValue) {
      this.getPath(newValue);
    },
  },
  methods: {
    /**
     * 展开menu值
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-15
     * @param   {Number}    index   需要toggle展开属性的index
     */
    toggleExpand(index) {
      if (
        !this.sideMenu ||
        !this.sideMenu.length ||
        this.sideMenu.length <= index
      ) {
        return;
      }
      this.menus[index].expanded = !this.menus[index].expanded;
      this.menus.push({});
      this.menus.pop();
      store.dispatch("setSideMenu", this.menus);
    },
    /**
     * 页面跳转
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     * @param  {Object}   menu
     * @param  {Number}   index 被点击menu的index值
     */
    toMenu(menu, index) {
      if (menu.children && menu.children.length) {
        this.toggleExpand(index);
      } else {
        router.push(menu.url);
      }
    },
    /**
     * 根据当前的url显示菜单
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     * @param  {String}   path 当前的地址
     */
    getPath(thisrouter) {
      let hasParams = true;
      console.log(thisrouter);
      for (let key in thisrouter.params) {
        if (key) {
          hasParams = false;
        }
      }
      console.log(hasParams);
      if (hasParams) {
        switch (thisrouter.path) {
          // 账户权限
          case URL.AUTHORITY:
            this.path = URL.AUTHORITY;
            break;
          // 市场
          case URL.MARKET_MANAGE:
          case URL.MARKET_DETAIL:
            this.path = URL.MARKET_MANAGE;

            break;
          // 操作日志
          case URL.OPERATION_LOG:
            this.path = URL.OPERATION_LOG;
            break;
          // 客户
          case URL.CUSTOMER:
            this.path = URL.CUSTOMER;
            break;
          // 商品
          case URL.PRODUCT:
            this.path = URL.PRODUCT;
            break;
          // 商品分类
          case URL.PRODU_CATEGORY:
            this.path = URL.PRODU_CATEGORY;
            break;
          // 加载失败
          case URL.LOAD_FAIL:
            this.path = localStorage.getItem("loadFailToPath");
            break;
          // 版本管理
          case URL.VERSION:
            this.path = URL.VERSION;
            break;
          // 设备管理
          case URL.DEVICE:
            this.path = URL.DEVICE;
            break;
          // 渠道商
          case URL.CHANNEL:
            this.path = URL.CHANNEL;
            break;
          case URL.ANNOUNCEMENT:
            this.path = URL.ANNOUNCEMENT;
            break;
          case URL.PROPERTY:
            this.path = URL.PROPERTY;
            break;
          //设备升级
          case URL.EquipmentUpgrade:
            this.path = URL.EquipmentUpgrade;
            break;
          //数据屏管理
          case URL.SCREEN_MANAGE:
            this.path = URL.SCREEN_MANAGE;
            break;
          //支付账户管理
          case URL.PAYREG:
            this.path = URL.PAYREG;
            break;
          //市场群组
          case URL.MARKET_GROUP:
            this.path = URL.MARKET_GROUP;
            break;
          //企业大屏
          case URL.COMPANY_SCREEN:
            this.path = URL.COMPANY_SCREEN;
            break;
          //信发屏
          case URL.SCREEN_AD:
            this.path = URL.SCREEN_AD;
            break;
          // 监管平台
          case URL.SUPERVISE:
            this.path = URL.SUPERVISE;
            break;

          default:
            break;
        }
      } else {
        switch (thisrouter.name) {
          case "marketdetail":
            this.path = URL.MARKET_MANAGE;
            break;

          case "customerDetail":
            this.path = URL.CUSTOMER;
            break;
          case "category":
            this.path = URL.CUSTOMER;
            break;
          case "costType":
            this.path = URL.CUSTOMER;
            break;
          case "diyMenu":
            this.path = URL.CUSTOMER;
            break;

          default:
            break;
        }
      }
    },
  },
  mounted() {
    this.getPath(this.$route);
  },
};
</script>
<style lang="less">
// @import (reference) "~less/base/utils.less";
// @import (reference) "~less/base/global.less";
@import url("../less/base/utils.less");
@import url("../less/base/global.less");
@import url("../less/base/font.less");
@menuHight: 34px;
#sidebar {
  .dib();
  .bgc(#000000);
  .wh(@sidebarWidth, 100%);
  .mgt(-@headHeight);
  .pos-r();
  .va-t();
}
#sidebar .logo {
  height: 95px;
  color: #ffffff;
  line-height: 95px;
  .ta-c();
  .cs();
  i {
    .fs(32px);
    &.ss-sushang {
      .fs(38px);
      color: #257873;
    }
  }
  top: 64px;
}
#sidebar .bar-info {
  .fc(12px, #6b7791);
  ul {
    .pd(0);
  }
  li {
    list-style: none;
    .p-menu {
      .nowrap();
      height: @menuHight;
      line-height: @menuHight;
      .cs();
      .pdl(17px);
      .va-m();
      > i {
        .fs(14px);
        .mgr(8px);
        &.ss-return,
        &.ss-unfold {
          float: right;
        }
      }
      &.active {
        color: #ffffff;
        .bgc(#257873);
        font-weight: bold;
      }
      &.is-son {
        .pdl(34px);
        .bgc(#123);
        .transition-all-500();
        &.active {
          color: #bbb;
        }
      }
    }
    .c-menu {
      .bgc(#040608);
      .pdl(38px);
      .mg(0);
      max-height: 0;
      overflow: hidden;
      > li {
        .cs();
        height: @menuHight;
        line-height: @menuHight;
        &.active {
          color: #257873;
          font-weight: bold;
        }
      }
    }
    &.open {
      .c-menu {
        max-height: 999px;
        transition: max-height 2.5s ease-in-out;
      }
    }
  }
}
#sidebar .foot {
  position: absolute;
  font-weight: bold;
  .wh(120px, 18px);
  line-height: 18px;
  .fc(18px, #fff);
  .ta-c();
  top: 62px;
  left: 20px;
  // .pos-abl(1, 0, 0);
}
#sidebar .has-son {
  display: flex;
  align-items: center;
  .pdr(17px);
  span {
    flex: 1;
    user-select: none;
  }
  &:after {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: "\e90f";
    font-family: "sdnm" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    .fs(10px);

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(0deg);
  }
  &.expanded:after {
    transform: rotate(180deg);
  }
}
</style>
