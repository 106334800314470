<template>
  <div id="topHeader">
    <div class="h-right useinfos-open">
      <div class="useinfos-open" @click="showUserinfoManage">
        <span class="useinfos-open">{{ userInfo.username }}</span>
        <i class="sdnm-triangle_bottom useinfos-open"></i>
      </div>
    </div>
    <div class="userinfos" v-if="userinfoShow">
      <ul class="userinfos-info">
        <!-- <li class="userinfos-info" @click="changePsw">修改密码</li> -->
        <li class="userinfos-info" @click="logout">注销</li>
      </ul>
    </div>
    <layer
      :show="editPsw.show"
      title="修改密码"
      :width="450"
      :height="320"
      saveBtn="修改"
      :saveCallBack="saveLayer"
      :closeCallBack="cancelLayer"
    >
      <div class="wp-change-psw form">
        <div class="field">
          <label>旧密码：</label>
          <input
            type="password"
            class="w300"
            maxlength="6"
            v-model="editPsw.oldpsw"
          />
        </div>
        <div class="field">
          <label>新密码：</label>
          <input
            type="password"
            class="w300"
            maxlength="6"
            v-model="editPsw.newpsw"
          />
        </div>
        <div class="field">
          <label>确认新密码：</label>
          <input
            type="password"
            class="w300"
            maxlength="6"
            v-model="editPsw.newpsw2"
          />
        </div>
      </div>
    </layer>
  </div>
</template>

<script>
/**
 * @module components/topHeader
 * @desc 传蔬农贸版 顶部
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-26
 * @copyright 2021
 */
import { mapGetters, mapActions } from "vuex";
import { apiPost } from "../modules/utils";
// import layer from '@@/layers';
import layer from "./layers";
import APIs from "../modules/APIs";
// import router from "@/router";
import router from "../router";
import * as URL from "../modules/URLs";
const getters = mapGetters(["userInfo"]);
const actions = mapActions(["setAlert", "clearUserInfo"]);
export default {
  name: "topheader",
  computed: {
    ...getters,
  },
  components: { layer },
  data() {
    return {
      isBoss: false,
      marketsShow: false,
      userinfoShow: false,
      newMsgShow: false,
      markets: [],
      marketsManageShow: false,
      editPsw: {
        // 修改密码layer配置
        show: false,
        oldpsw: "",
        newpsw: "",
        newpsw2: "",
      },
    };
  },
  watch: {
    userinfoShow(newValue, oldValue) {
      if (newValue && !oldValue) {
        window.addEventListener("click", this.windowUserClick);
      } else {
        window.removeEventListener("click", this.windowUserClick);
      }
    },
  },
  methods: {
    ...actions,
    showUserinfoManage() {
      this.userinfoShow = true;
    },
    /**
     * 修改密码
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-14
     */
    changePsw() {
      this.userinfoShow = false;
      this.editPsw.oldpsw = "";
      this.editPsw.newpsw = "";
      this.editPsw.newpsw2 = "";
      this.editPsw.show = true;
    },
    cancelLayer() {
      this.editPsw.show = false;
    },
    saveLayer() {
      if (!this.editPsw.oldpsw) {
        this.setAlert({ msg: "请输入密码" });
        return;
      }
      if (!this.editPsw.newpsw) {
        this.setAlert({ msg: "请输入新密码" });
        return;
      }
      if (!this.editPsw.newpsw2) {
        this.setAlert({ msg: "请确认新密码" });
        return;
      }
      if (!(this.editPsw.newpsw === this.editPsw.newpsw2)) {
        this.setAlert({ msg: "两次新密码输入不一致" });
        return;
      }
      let $this = this;
      apiPost({
        url: APIs.SMS,
        data: {
          action: "account.change.password",
          new_password: this.editPsw.newpsw,
          old_password: this.editPsw.oldpsw,
        },
        success(res) {
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
          if (res.code === "0") {
            $this.editPsw.show = false;
          }
        },
        error(res) {
          $this.setAlert({ msg: "操作失败" });
        },
      });
    },
    /**
     * 注销
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-01-09
     */
    logout() {
      let $this = this;
      apiPost({
        url: APIs.LOGIN,
        data: {
          action: "account.logout",
        },
        success(res) {
          if (res.code === "0") {
            $this.clearUserInfo();
            router.push(URL.LOGIN);
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(res) {
          $this.setAlert({ msg: "登出失败", type: false });
        },
      });
    },
    windowUserClick(event) {
      let className = event.target.className;
      let csNames = [
        "h-right useinfos-open",
        "useinfos-open",
        "sdnm-triangle_bottom useinfos-open",
        "userinfos",
        "userinfos-info",
      ];
      if (csNames.indexOf(className) === -1) {
        if (this.userinfoShow) {
          this.userinfoShow = false;
        }
      }
    },
  },
  mounted() {
    // if(this.userInfo.role_name === CONST.ADMIN_BOSS){
    // 	this.isBoss = true;
    // }
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
#topHeader {
  .wp-change-psw {
    .mgt(20px);
    label {
      width: 120px;
      text-align: right;
    }
  }
  position: fixed;
  .wh(calc(~"100% - 160px"), @headHeight);
  .mgl(160px);
  .bgc(#ffffff);
  box-shadow: 0 2px 4px 0 #e1e8ed;
  .pos-r();
}
#topHeader .h-left {
  .dib();
  height: @headHeight;
  line-height: @headHeight;
  .cs();
  .fc(12px, #a0b9db);
  > span {
    .mgl(24px);
    .dib();
    width: 160px;
  }
  > i {
    .fs(10px);
    .mgr(24px);
  }
  .title-right {
    .dib();
    > div {
      .dib();
      .fc(12px, #666666);
      height: @headHeight;
      line-height: @headHeight;
      &:first-child {
        &:after {
          content: "";
          .dib();
          .wh(2px, 8px);
          .mgl(16px);
          .mgr(16px);
          .bgc(#e6e8f0);
        }
      }
    }
    .msg {
      .pos-r();
      > i {
        .fc(14px, #a0b9db);
      }
      > span {
        position: absolute;
        height: 16px;
        .pd(1px, 5px, 1px, 4px);
        .bgc(#f84445);
        .fc(10px, #ffffff);
        top: 7px;
        left: 7px;
        .brdr(4px);
        line-height: 14px;
        -webkit-transform-origin-x: 0;
        -webkit-transform: scale(0.9);
      }
    }
    .home {
      > i {
        .fc(14px, #a0b9db);
      }
    }
  }
}
#topHeader .h-right {
  .pos-atr(1, 0, 0);
  .cs();
  > div {
    .dib();
    .fc(12px, #666666);
    height: @headHeight;
    line-height: @headHeight;
    &:last-child {
      .mgr(24px);
    }
    // &:first-child{
    // 	&:after{
    // 		content: '';
    // 		.dib();
    // 		.wh(2px, 8px);
    // 		.mgl(16px);
    // 		.mgr(16px);
    // 		.bgc(#E6E8F0);
    // 	}
    // }
  }
}
#topHeader .market-manage {
  .pd(24px);
}
#topHeader .market-manage {
  table {
    .operation {
      i {
        color: #a0b9db;
        &:first-child {
          .mgr(20px);
        }
      }
    }
    .edit-operation {
      i {
        color: #f84445;
        &:first-child {
          .mgr(20px);
          color: #2ea321;
        }
      }
    }
  }
}
// #topHeader .new-msgs{
// 	.wh(310px, 266px);
// 	position: absolute;
// 	.bgc(#fff);
// 	z-index: 99;
// 	.brdr(4px);
// 	box-shadow: 0 0 12px 0 rgba(83,92,105,0.12);
// 	right: 100px;
// 	top: 50px;
// 	.pdl(16px);
// 	.pdr(16px);
// 	> div{
// 		.hlh(50px, 50px);
// 		border-bottom: 1px solid #ECEFF3;
// 		.pos-r();
// 	}
// 	.title{
// 		.fc(14px, #253650);
// 		> span:last-child{
// 			.fc(12px, #257873);
// 			position: absolute;
// 			right: 0;
// 		}
// 	}
// 	.msg-content{
// 		> span:last-child{
// 			.fc(12px, #AEB5BF);
// 			position: absolute;
// 			right: 0;
// 		}
// 	}
// }
</style>
