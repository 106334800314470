<template>
  <div id="app">
    <alert></alert>
    <tophead v-if="showMenu"></tophead>
    <sidebar v-if="showMenu"></sidebar>
    <router-view
      class="router-view"
      :style="{ height: viewHeigth + 'px' }"
    ></router-view>
  </div>
</template>

<script>
// import sidebar from "@@/sidebar";
// import tophead from "@@/topHeader";
// import alert from "@@/alert";
import sidebar from "./components/sidebar";
import tophead from "./components/topHeader";
import alert from "./components/alert";
export default {
  name: "app",
  components: { sidebar, tophead, alert },
  data() {
    return {
      viewHeigth: 0,
      showMenu: !true,
      noShowMenu: ["login"],
    };
  },
  watch: {
    $route(newValue, oldValue) {
      if (this.noShowMenu.includes(newValue.name)) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
  },
  methods: {
    resize() {
      let documentHeight = document.body.clientHeight;
      this.viewHeigth = documentHeight - 50;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener(
      "resize",
      () => {
        this.resize();
      },
      false
    );
  },
};
</script>

<style lang="less">
// @import "~less/common.less";
// @import "~less/login.less";
// @import "~less/base/utils.less";
// @import "~less/base/global.less";
@import url("./less/common.less");
@import url("./less/login.less");
@import url("./less/base/utils.less");
@import url("./less/base/global.less");
@import url("./less/base/font.less");
html,
body {
  font-family: "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  background-color: #eceff3;
  min-width: 1200px;
  overflow-y: hidden;
  overflow-x: auto;
}
/*滚动条*/
::-webkit-scrollbar-track-piece {
  width: 8px;
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #aeaeae;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  background-color: #aeaeae;
}
::-webkit-scrollbar-thumb:hover {
  width: 8px;
  background-color: #aeaeae;
}
/*滚动条 end*/
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#app {
  height: 100%;
}
.router-view {
  display: inline-block;
}
</style>
