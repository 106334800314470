/**
 * 常量定义
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 */
export const CODE_NOLOGIN = '-22222'; // 未登录
export const PAY_TYPE_ONE = 1; // 缴费模式 - 一次性缴费
export const AREA_CODE_CHAIN = 10000; // 固定的中国的code
export const AREA_NAME_CHAIN = '中国'; // 固定的中国名字
export const PAY_TYPE_CYCLE = 2; // 缴费模式 - 周期性缴费
export const CHARGE_STANDARD_FIXED_CYCLE = 1; // 计费标准 - 固定周期费用
export const CHARGE_STANDARD_SECOND = 2; // 计费标准 - 按抄表数
export const CHARGE_STANDARD_SALES_CYCLE = 3; // 计费标准 - 按周期内营业额
export const CHARGE_STANDARD_END = 4; // 计费标准 - 按百分比提成
// export const CHARGE_STANDARD_PERCENT = 5; // 计费标准 - 按营业额百分比计算提成
export const CHARGE_STANDARD_TEMPORARY = 9; // 临时收费
export const ADJUST_SALES = 1; // 调整项 - 营业额缴款
// export const ADJUST_PURCHASE = 2; // 调整项 - 供应链采购金额
export const PAY_TYPES = [
	{
		id: PAY_TYPE_ONE,
		name: '一次性缴费',
	},
	{
		id: PAY_TYPE_CYCLE,
		name: '周期性缴费',
	}
];
export const CHARGE_STANDARDS = [
	{
		id: CHARGE_STANDARD_FIXED_CYCLE,
		name: '固定周期费用',
	},
	{
		id: CHARGE_STANDARD_SECOND,
		name: '按抄表数',
	},
	{
		id: CHARGE_STANDARD_SALES_CYCLE,
		name: '按营业额固定金额提成',
	},
	{
		id: CHARGE_STANDARD_END,
		name: '按营业额百分比计算提成',
	},
	// {
	// 	id: CHARGE_STANDARD_PERCENT,
	// 	name: '按营业额百分比计算提成',
	// },
];
export const ADJUSTS = [
	{
		id: ADJUST_SALES,
		name: '营业额缴款',
	},
	// {
	// 	id: ADJUST_PURCHASE,
	// 	name: '供应链采购金额'
	// }
];
export const VALIDATE_TYPES = {
	NONE: 0,
	MOBILE: 1,
	EMAIL: 2,
	IDCARD: 3,
};
