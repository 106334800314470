/**
 * @file vuex/mutations.js
 * @desc 传蔬-农贸版运营后台 vuex mutations
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
import * as mutationTypes from './mutation-types';
let {
	SET_ALERT,
	SET_SIDE_MENU,
	SET_USER_INFO,
	CLEAR_USER_INFO,
	SET_APPTITLE,
} = mutationTypes;
export default {
	/**
	 * 设置错误信息
	 * @author songkexin <songkexin@rongyi.com>
	 * @date   2021-12-20
	 * @param  {Object} state
	 * @param  {String} msg 错误信息
	 */
	[SET_ALERT](state, { msg, type }){
		state.alert = {
			msg: msg,
			type: type
		};
	},
	/**
	 * 获取左侧菜单的列表
	 * @author songkexin <songkexin@rongyi.com>
	 * @date   2021-01-09
	 * @param  {Object} state
	 * @param {object} data 侧边菜单数据
	 */
	[SET_SIDE_MENU](state, data){
		state.sideMenu = data;
		// state.btnAddRole = false;
		// let usePaths = data.map(el => {
		// 	if(el.id === CONST.MENU_ACCOUNT_ID){
		// 		el.children.forEach(cel => {
		// 			if(cel.id === CONST.MENU_ACCOUNT_ADD_ROLE_ID){
		// 				state.btnAddRole = true;
		// 			}
		// 		});
		// 	}
		// 	return el.url;
		// });
		// localStorage.setItem('usePaths', JSON.stringify(usePaths));
	},
	/**
	 * 设置用户信息
	 * @author wurongjian <wurongjian@rongyi.com>
	 * @date   2021-09-28
	 * @param  {Object} state
	 * @return {object} data 用户信息
	*/
	[SET_USER_INFO](state, data){
		state.userInfo = data;
	},
	/**
	 * 清空用户信息
	 * @author songkexin <songkexin@rongyi.com>
	 * @date   2021-01-09
	 * @param  {Object} state
	 */
	[CLEAR_USER_INFO](state){
		state.userInfo = {
			id: '', // 用户id
			mobile: '', // 用户手机号
			username: '', // 用户名称
		};
	},
	/**
	 * 设置页面的标题
	 * @author songkexin <songkexin@rongyi.com>
	 * @date   2021-01-09
	 * @param  {Object} state
	 * @param {Array} msg 页面的标题数据
	 */
	[SET_APPTITLE](state, data){
		state.appTitle = data;
	},

};
