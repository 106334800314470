// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import zhCn from "vee-validate/dist/locale/zh_CN";
import Vue from "vue";
import store from "./vuex";
import App from "./App";
import router from "./router";
import VueResource from "vue-resource";
import flatPickr from "vue-flatpickr-component";
// import * as URL from './modules/URLs';
import APIs from "./modules/APIs";
import * as CONST from "./modules/CONSTs";
import Loadbtn from "./mixins/loadbtn";
import VeeValidate from "vee-validate";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/less/element-ui-reset.less";
import "./assets/style/element-variables.scss";
import Video from "video.js";
import "video.js/dist/video-js.css";

Vue.prototype.$video = Video;

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueResource);
Vue.use(flatPickr);
Vue.use(Loadbtn);
VeeValidate.Validator.localize("zh_CN", zhCn);
const config = {
  errorBagName: "errorBags", // change if property conflicts.
  fieldsBagName: "fieldBags",
};
Vue.use(VeeValidate, config);
Vue.use(ElementUI);

/* eslint-disable no-new */
// 登录认证
router.beforeEach((to, from, next) => {
  if (typeof to.meta.appTitle === "undefined") {
    store.dispatch("setApptitle", []);
  } else {
    store.dispatch("setApptitle", to.meta.appTitle);
  }
  if (to.meta.requireAuth) {
    // 在auto认证的范围之内，且用户的信息不为空，市场的信息也不为空，可以直接跳转页面
    if (store.state.userInfo.id !== "" && store.state.sideMenu.length !== 0) {
      next();
    } else {
      let getMenuData = new Promise(function(resolve, reject) {
        Vue.http
          .post(APIs.LOGIN, {
            action: "account.menu",
          })
          .then(
            (res) => {
              if (res.data.code === "0") {
                resolve(res.data.data);
              } else {
                reject(res.data.code);
              }
            },
            (res) => {
              reject(res);
            }
          );
      });
      let getUserData = new Promise(function(resolve, reject) {
        Vue.http
          .post(APIs.LOGIN, {
            action: "account.user.info",
          })
          .then(
            (res) => {
              if (res.data.code === "0") {
                resolve(res.data.data);
              } else {
                reject(res.data.code);
              }
            },
            (res) => {
              reject(res);
            }
          );
      });
      Promise.all([getMenuData, getUserData])
        .then((values) => {
          store.dispatch("setSideMenu", values[0]);
          store.dispatch("setUserInfo", values[1]);
          if (to.path === URL.LOGIN) {
            next({ path: URL.HOME });
          } else {
            next();
          }
        })
        .catch((values) => {
          next();
          if (values === CONST.CODE_NOLOGIN) {
            next({ path: URL.LOGIN });
          }
        });
    }
  } else {
    // 不需要登录认证 如登录页面
    // if(store.state.userInfo.user_id !== ''){
    // 	// 如果有用户信息
    // 	next({ path: URL.HOME });
    // }
    // else{
    next();
    // }
  }
});
new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: { App },
});
