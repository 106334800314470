/**
 * @file vuex/getters.js
 * @desc 传蔬-农贸版运营后台 vuex getters
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
export const userInfo				=	state => state.userInfo; // 用户信息
export const alert					=	state => state.alert; // 错误信息
export const sideMenu				=	state => state.sideMenu; // 侧边菜单目录
export const appTitle				=	state => state.appTitle; // 页面的标题数据
