/**
 * @file vuex/state.js
 * @desc 传蔬-农贸版运营后台 vuex state
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
export default {
	alert: { // 错误信息
		msg: '',
		type: false,
	},
	sideMenu: [],
	userInfo: {
		id: '', // 用户id
		mobile: '', // 用户手机号
		username: '', // 用户名称
	},
	appTitle: [], // 页面的标题
};
