<template>
  <div id="alert" class="alert-frame" :class="{ show: alert.msg !== '' }">
    <div class="icon" v-if="alert.type && alert.type !== ''">
      <i class="sdnm-correct_circular right"></i>
    </div>
    <div class="icon" v-if="!alert.type && alert.type !== ''">
      <i class="sdnm-error_circular wrong"></i>
    </div>
    <div class="icon" v-if="alert.type === ''">
      <i class="sdnm-sigh_circular warn"></i>
    </div>
    <div class="msg-content">{{ alert.msg }}</div>
    <i class="sdnm-close btn-close" @click="close"></i>
  </div>
</template>

<script>
/**
 * @module components/alert
 * @desc 速商 提示框
 * @version 0.0.2
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-07-12
 * @copyright 2021
 */
import { mapGetters, mapActions } from "vuex";
export default {
  name: "alert",
  computed: {
    ...mapGetters(["alert"]),
  },
  methods: {
    ...mapActions(["setAlert"]),
    close() {
      this.alert.msg = "";
    },
  },
  watch: {
    "alert.msg": function(newValue, oldValue) {
      if (newValue !== "") {
        let time = 3000;
        if (this.alert.type === true) {
          time = 2000;
        } else if (this.alert.type === false) {
          time = 3000;
        }
        setTimeout(() => {
          this.setAlert({ msg: "", type: this.alert.type });
          // this.alert.msg = '';
        }, time);
      }
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
