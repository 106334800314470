/**
 * @file vuex/index.js
 * @desc 传蔬-农贸版运营后台 vuex index
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 * @copyright 2021
 */
import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';
import state from './state';

Vue.use(Vuex);
export default new Vuex.Store({
	state,
	actions,
	getters,
	mutations,
});
