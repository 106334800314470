/**
 * 所有接口地址
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-01-09
 */
export default {
  LOGIN: "/api/login",
  AUTHORITY: "/api/user",
  COMPANY: "/api/company",
  LOG: "/api/log",
  SMS: "/api/sms/main",
  MENU: "/api/menu",
  PRODUCT: "/api/product/main",
  VERSION: "/api/version/main",
  OEM_VERSION: "/api/version/oem",
  POS: "/api/pos/main",
  CHANNEL: "/api/channel/main",
  ANNOUNCEMENT: "/api/announcement/main",
  PROPERTY: "api/property/main",
  UPDATE: "/api/version/update",
  SCREEN: "/api/screen/main",
  PAYMENT: "/api/payment/permer",
  ABC: "/api/payment/abc",
  MARKETGROUP: "api/supervise/main",
};
